const defaultData = {
  // 默认 封面 /目录页 /章节页 /封底
  // slideType/页面类型： Cover/封面, Catalogue/目录， Chapter/章节，Chart/图表, BackCover/封底
  // elementType/内容类型: TextBox/文本， Title/标题， Image/图片, Chart/图表, Catalogue/目录

  initialPPT: [
    {
      slideType: 'Cover',
      name: '封面',
      settings: {
        background: 'd03a2b',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68wfb81e-4b1b-427e-999e-154fb81239c2.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '标题' },
          settings: {
            position: {
              x: '15%',
              y: '29%',
              w: '79.53%',
              h: '9%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 32,
              bold: true,
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '副标题' },
          settings: {
            position: {
              x: '15%',
              y: '38%',
              w: '79.53%',
              h: '7%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 24,
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '一级部门二级部门' },
          settings: {
            position: {
              x: '15%',
              y: '69%',
              w: '40.53%',
              h: '6%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 24,
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '年月日' },
          settings: {
            position: {
              x: '15%',
              y: '75%',
              w: '40.53%',
              h: '6%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 20,
            },
          },
        },
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w45403-3d0e-4dc5-9b2e-cd098780e91d.png',
          },
          settings: {
            position: {
              x: '55.896%',
              y: '41.051%',
              w: '44.105%',
              h: '58.95%',
            },
          },
        },
      ],
    },
    {
      slideType: 'Catalogue',
      name: '目录',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: { maintitle: '目录' },
          settings: {
            titleStyle: 'catalogue',
          },
        },
        {
          elementType: 'Catalogue',
          content: {
            catalogueList: [
              {
                text: '标题1',
              },
              {
                text: '标题2',
              },
              {
                text: '标题3',
              },
              {
                text: '标题4',
              },
            ],
          },
          settings: {
            textStyle: {
              color: '000000',
              fontSize: 30,
            },
            position: {
              w: '40%',
              x: '45%',
              y: '25%',
            },
          },
        },
      ],
    },
    {
      slideType: 'Chapter',
      name: '章节页',
      settings: {
        background: 'd03a2b',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68wfb81e-4b1b-427e-999e-154fb81239c2.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '01' },
          settings: {
            position: {
              x: '15%',
              y: '27%',
              w: '49.53%',
              h: '18%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 88,
              bold: true,
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '分隔页标题' },
          settings: {
            position: {
              x: '15%',
              y: '45%',
              w: '70%',
              h: '11%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 44,
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '分隔页标题' },
          settings: {
            position: {
              x: '15%',
              y: '56%',
              w: '70%',
              h: '9%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 36,
            },
          },
        },
      ],
    },
    {
      slideType: 'BackCover',
      name: '封底',
      settings: {
        background: 'd03a2b',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68wfb81e-4b1b-427e-999e-154fb81239c2.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: 'Thanks' },
          settings: {
            position: {
              x: '15%',
              y: '44%',
              w: '40.53%',
              h: '10%',
            },
            textStyle: {
              color: 'ffffff',
              fontSize: 44,
              bold: true,
            },
          },
        },
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w45403-3d0e-4dc5-9b2e-cd098780e91d.png',
          },
          settings: {
            position: {
              x: '55.896%',
              y: '41.051%',
              w: '44.105%',
              h: '58.95%',
            },
          },
        },
      ],
    },
  ],
  initialChart: {
    temp1: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '97.46%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp2: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '48.1%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '50.64%',
              y: '29.09%',
              w: '48.1%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp3: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '97.46%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '59.4%',
              w: '97.46%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp4: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '48.1%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '50.64%',
              y: '29.09%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '50.64%',
              y: '59.4%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp5: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '31.64%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '34.18%',
              y: '29.09%',
              w: '31.64%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '67.09%',
              y: '29.09%',
              w: '31.64%',
              h: '58%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp6: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '50.64%',
              y: '29.09%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '59.4%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '50.64%',
              y: '59.4%',
              w: '48.1%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
    temp7: {
      slideType: 'Chart',
      name: '图表页',
      settings: {
        background: 'ffffff',
      },
      elements: [
        {
          elementType: 'Image',
          content: {
            imgurl: 'https://i1.mallcoo.cn/sp_mall/68w2b066-82ef-49ff-9625-7ba5ca0a7115.png',
          },
          settings: {
            position: {
              x: '83.946%',
              y: '4.83%',
              w: '10.334%',
              h: '7.35%',
            },
          },
        },
        {
          elementType: 'Title',
          content: {
            maintitle: '标题',
            subtitle: '副标题',
          },
          settings: {
            titleStyle: 'main',
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '18.18%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '29.09%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '34.18%',
              y: '29.09%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '67.09%',
              y: '29.09%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },

        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '59.4%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '34.18%',
              y: '59.4%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'Chart',
          definition: '',
          content: { imgurl: '' },
          settings: {
            position: {
              x: '67.09%',
              y: '59.4%',
              w: '31.64%',
              h: '27.69%',
            },
          },
        },
        {
          elementType: 'TextBox',
          content: { text: '' },
          settings: {
            position: {
              x: '1.27%',
              y: '89%',
              w: '97.46%',
              h: '9%',
            },
            textStyle: {
              color: '000000',
              fontSize: 12,
            },
          },
        },
      ],
    },
  },
  initialChapter: {
    slideType: 'Chapter',
    name: '章节页',
    settings: {
      background: 'd03a2b',
    },
    elements: [
      {
        elementType: 'Image',
        content: {
          imgurl: 'https://i1.mallcoo.cn/sp_mall/68wfb81e-4b1b-427e-999e-154fb81239c2.png',
        },
        settings: {
          position: {
            x: '83.946%',
            y: '4.83%',
            w: '10.334%',
            h: '7.35%',
          },
        },
      },
      {
        elementType: 'TextBox',
        content: { text: '01' },
        settings: {
          position: {
            x: '15%',
            y: '27%',
            w: '49.53%',
            h: '18%',
          },
          textStyle: {
            color: 'ffffff',
            fontSize: 88,
            bold: true,
          },
        },
      },
      {
        elementType: 'TextBox',
        content: { text: '分隔页标题' },
        settings: {
          position: {
            x: '15%',
            y: '45%',
            w: '70%',
            h: '11%',
          },
          textStyle: {
            color: 'ffffff',
            fontSize: 44,
          },
        },
      },
      {
        elementType: 'TextBox',
        content: { text: '分隔页标题' },
        settings: {
          position: {
            x: '15%',
            y: '56%',
            w: '70%',
            h: '9%',
          },
          textStyle: {
            color: 'ffffff',
            fontSize: 36,
          },
        },
      },
    ],
  },
}
export default defaultData
