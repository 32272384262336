<template>
  <div class="reportList">
    <div class="reportSort">
      <span v-for="(sort, index) in sortlist" :class="{ active: sort.active }" @click="sortclick(index)" :key="index"
        >{{ sort.name }}<i class="el-icon-bottom"></i
      ></span>
    </div>
    <ul v-if="showList.length > 0">
      <li v-for="(item, index) in showList" :key="index">
        <div class="space-set">
          <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
            <span v-if="$commonJs._point('BI_F0031')" @click="editReport(item)" class="el-icon-edit-outline"></span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
            <span v-if="$commonJs._point('BI_F0033')" @click="copyReport(item)" class="el-icon-document-copy"></span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="导出PPT" placement="top-start">
            <span v-if="$commonJs._point('BI_F0034')" @click="downloadReport(item)" class="el-icon-download"></span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <span v-if="$commonJs._point('BI_F0032')" @click="delReport(item)" class="el-icon-delete"></span>
          </el-tooltip>
        </div>
        <h4 class="wordellipsis">{{ item.name }}</h4>
        <div class="space-desc">{{ item.content }}</div>
        <div class="members">
          <!-- <i class="el-icon-user-solid"></i> -->
          <div class="memberList wordellipsis">
            <span> {{ item.updater }}</span>
            <span> {{ item.updateTime }}</span>
          </div>
        </div>
        <div class="enterbtn">
          <div v-if="$commonJs._point('BI_F0035')" @click="enter(item)" class="hignopcitybg"><span></span><b>点击进入</b></div>
        </div>
      </li>
    </ul>
    <div class="nospace dis_flex_justify_center" v-if="showList.length === 0">
      <div>
        <img src="@/assets/images/nodata/nospace.png" alt />
        <span>暂时没有数据报告~</span>
      </div>
    </div>
    <addEditReport :dialogEditPPT="dialogEditPPT" :editinfo="edititem" @changeAddEditPPT="changeAddEditPPT" @changepptlist="changepptlist" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { copypresentation, deletepresentation, getpresentation } from '@/api/reportAPI'
import addEditReport from './addEditReport'
import exportpptjs from '../pages/exportPPTjs/exportPPT'
import BI_config from '@/utils/config'
export default {
  props: {
    list: {
      default: [],
    },
  },
  components: { addEditReport },
  data() {
    return {
      dialogEditPPT: false,

      edititem: '',
      showList: [],
      sortlist: [],
    }
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
  },
  created() {
    //路由权限 没权限跳转404
    if (!this.$commonJs._point('BI_M0107')) {
      this.$router.push({ path: `${BI_config.dashboardProxy}/404` })
    }
    this.sortlist = [
      {
        name: '按创建时间排序',
        type: 'createtime',
        active: true,
        sortdown: true,
      },
      {
        name: '按更新时间排序',
        type: 'updatatime',
        active: false,
        sortdown: true,
      },
      {
        name: '按名称排序',
        type: 'name',
        active: false,
        sortdown: true,
      },
    ]
    this.initsort()
  },
  methods: {
    sortclick(idx) {
      this.sortlist.forEach((x, i) => {
        if (i === idx) {
          if ((x.active = true)) {
            x.sortdown = !x.sortdown
          } else {
            x.active = true
          }
        } else {
          x.active = false
          x.sortdown = true
        }
      })
      this.initsort()
    },
    initsort() {
      const curSort = this.sortlist.find(x => x.active)
      this.showList = this.list.sort((a, b) => {
        // Safari new Date 兼容
        a.createTime = a.createTime.replace(/-/g, '/')
        a.updateTime = a.updateTime.replace(/-/g, '/')
        b.createTime = b.createTime.replace(/-/g, '/')
        b.updateTime = b.updateTime.replace(/-/g, '/')
        if (curSort.type === 'createtime') {
          const aTime = new Date(a.createTime)
          const bTime = new Date(b.createTime)
          return curSort.sortdown ? bTime - aTime : aTime - bTime
        }
        if (curSort.type === 'updatatime') {
          const aTime = new Date(a.updateTime)
          const bTime = new Date(b.updateTime)
          return curSort.sortdown ? bTime - aTime : aTime - bTime
        }
        if (curSort.type === 'name') {
          const aTime = a.name
          const bTime = b.name
          return curSort.sortdown ? aTime.localeCompare(bTime) : bTime.localeCompare(aTime)
        }
      })
    },
    changeAddEditPPT(v) {
      this.dialogEditPPT = v
    },
    editReport(item) {
      this.edititem = item
      this.dialogEditPPT = true
    },
    copyReport(item) {
      this.$confirm('确定复制该数据报告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const param = {
            presentationID: item.id,
          }
          copypresentation(param).then(v => {
            this.$message({
              type: 'success',
              message: '复制成功!',
            })
            this.$emit('changepptlist')
          })
        })
        .catch(() => {})
    },
    changepptlist() {
      this.$emit('changepptlist')
    },
    async downloadReport(item) {
      const loading = this.$loading({
        lock: true,
        text: '数据报告正在导出中… 请不要关闭该页面!',
        customClass: `customloading`,
        spinner: `cardloading loading${this.basicInfo.project.mainColor}`,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const pptres = await getpresentation(item.id)
      let pptdata = pptres.data
      pptdata.slides = pptdata.slides.map(x => {
        x.settings = JSON.parse(x.settings)
        x.elements.map(ele => {
          if (ele.definition) {
            ele.definition = JSON.parse(ele.definition)
          }
          if (ele.content) {
            ele.content = JSON.parse(ele.content)
          }
          if (ele.settings) {
            ele.settings = JSON.parse(ele.settings)
          }
          return ele
        })

        return x
      })
      exportpptjs.ppt_export(pptdata, loading)
    },
    delReport(item) {
      this.$confirm('确定删除该数据报告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          deletepresentation(item.id).then(v => {
            const spaceidx = this.showList.map(x => x.id).indexOf(item.id)
            this.showList.splice(spaceidx, 1)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.$emit('changepptlist')
          })
        })
        .catch(() => {})
    },
    enter(item) {
      const projectID = this.$route.params?.projectID
      this.$router.push({ path: `${BI_config.dashboardProxy}/reportPages/${projectID}/${item.id}` })
    },
  },
}
</script>

<style lang="scss" scoped>
.reportList {
  margin-bottom: 20px;
  .reportSort {
    margin-bottom: 20px;
    span {
      display: inline-block;
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;
      &:hover,
      &.active {
        color: #333;
        background: #ececec;
      }
      i {
        font-size: 12px;
      }
    }
  }
  ul {
    display: flex;
    flex-flow: wrap;
    li {
      position: relative;
      flex: 1;
      width: calc(25% - 12px);
      height: 200px;
      min-width: calc(25% - 12px);
      max-width: calc(25% - 12px);
      margin-right: 16px;
      margin-bottom: 15px;
      padding: 15px;
      background: #fff;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        @include high_border1($highcolor-cheng);
        .space-set {
          display: block;
        }
      }
      .space-set {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        span {
          margin-left: 10px;
          @include high_color1($highcolor-cheng);
          cursor: pointer;
        }
      }
      h4 {
        font-size: 20px;
        width: calc(100% - 90px);
      }
      .space-desc {
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
        color: #909399;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .members {
        width: 100%;
        font-size: 12px;
        color: #c0c4cc;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 40px;
        i {
          margin-right: 5px;
        }
        .memberList {
          max-width: calc(100% - 95px);
          font-size: 12px;
          span {
            display: block;
            line-height: 20px;
          }
        }
        .memberLens {
        }
      }
      .enterbtn {
        width: 80px;
        height: 32px;
        position: absolute;
        bottom: 15px;
        right: 20px;
      }
    }
  }
  .nospace {
    height: 250px;
    text-align: center;
    padding-top: 30px;
    img {
      display: block;
      width: 254px;
    }
    span {
      font-size: 14px;
      color: #909399;
    }
  }
}
</style>
