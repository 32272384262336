<template>
  <div class="report">
    <div class="report-main">
      <div v-if="$commonJs._point('BI_F0030')" class="report-top">
        <span @click="dialogAddPPT = true" class="addbtn"><i class="el-icon-plus"></i> 创建报告</span>
        <div class="search">
          <el-input v-model="keyword" @input="filterReport" placeholder="请输入关键字" size="small" prefix-icon="el-icon-search"> </el-input>
        </div>
      </div>
      <div v-if="reportData" class="mySpace">
        <report-list :list="showReportData" @changepptlist="changepptlist" :key="listkey" />
        <el-pagination
          v-if="reportData.length > 0"
          :current-page="paging.index"
          :page-sizes="paging.sizes"
          :page-size="paging.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="reportData.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <addEditReport :dialogAddPPT="dialogAddPPT" @changeAddEditReport="changeAddEditReport" @changepptlist="changepptlist" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import headerComponent from '@/components/headerComponent'
import addEditReport from './addEditReport'
import reportList from './reportList'
import { getMenuLoad, getUserInfo } from '@/api/apiV2_dashboard'
import BI_config from '@/utils/config'
import { postpptlist, postpresentation, putpresentation, deletepresentation } from '@/api/reportAPI.js'
export default {
  name: 'report',
  data() {
    return {
      headInfo: [],
      dialogAddPPT: false,
      reportData: null,
      showReportData: null,
      keyword: '',
      listkey: 0,
      paging: {
        index: 0,
        size: 0,
        sizes: [20, 30, 50],
        total: 100,
      },
    }
  },

  components: {
    headerComponent,
    addEditReport,
    reportList,
  },
  computed: {
    ...mapState({
      menus: state => state.menus,
      projectID: state => state.projectID,
      system: state => state.system,
    }),
  },
  async created() {
    if (this.projectID) {
      this.init()
    } else {
      this.$router.push({ path: `${BI_config.dashboardProxy}/list` })
    }
  },
  methods: {
    init() {
      this.getpostpptlist()
    },
    changeAddEditReport(item) {
      this.dialogAddPPT = item
    },
    filterReport() {
      const copyList = JSON.parse(JSON.stringify(this.reportData))
      this.showReportData = copyList.filter(x => {
        return x.name && this.$commonJs2.matchSoftly(x.name, this.keyword)
      })
      this.listkey++
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    getshowlist() {
      const copylist = JSON.parse(JSON.stringify(this.reportData))
      this.showReportData = copylist
      this.listkey++
    },
    changepptlist() {
      this.getpostpptlist()
    },
    async getpostpptlist() {
      const pptlistres = await postpptlist()
      this.reportData = pptlistres.data
      this.getshowlist()

      // 埋点
      this.$trackView({ system: this.system, module: '数据报告', pageName: '数据报告列表', path: this.$route.path })
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.report {
  width: 100%;
  padding-top: 60px;
  .report-main {
    padding: 20px;
    .report-top {
      width: 100%;
      height: 68px;
      background: #fff;
      padding-left: 20px;
      padding-top: 16px;
      .addbtn {
        float: left;
        display: block;
        width: 110px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border-radius: 20px;
        @include high_bg1($highcolor-cheng);
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
      }
      .search {
        float: right;
        padding-top: 2px;
        margin-right: 20px;
      }
    }
    .mySpace {
      margin-top: 10px;
      background: #fff;
      padding: 20px;
      border-radius: 6px;
    }
  }
}
</style>
