import request from '@/utils/request2'

//卡片列表
export function getpptmetadata() {
  return request({
    url: '/ppt/metadata',
    method: 'get',
  })
}

//获取书签
export function getBookmark(param, data) {
  return request({
    url: '/bookmark/' + param,
    method: 'get',
    data: data,
  })
}

//上传图片
export function postchartupload(data) {
  return request({
    url: '/ppt/chart/upload',
    method: 'post',
    data,
  })
}


// 数据报告列表
export function postpptlist() {
  return request({
    url: '/ppt/presentation/list',
    method: 'get',
  })
}

// 数据报告详情
export function getpresentation(id) {
  return request({
    url: '/ppt/presentation/' + id,
    method: 'get',
  })
}

// 创建数据报告
export function postpresentation(data) {
  return request({
    url: '/ppt/presentation',
    method: 'post',
    data,
  })
}

// 复制数据报告
export function copypresentation(data) {
  return request({
    url: '/ppt/presentation/copy',
    method: 'post',
    data,
  })
}

// 更新数据报告
export function putpresentation(data) {
  return request({
    url: '/ppt/presentation',
    method: 'put',
    data,
  })
}

// 删除数据报告
export function deletepresentation(id) {
  return request({
    url: '/ppt/presentation/' + id,
    method: 'delete',
  })
}

// 添加 PPT 页面
export function postpresentationslide(data) {
  return request({
    url: '/ppt/presentation/slide',
    method: 'post',
    data,
  })
}

// 更新 PPT 页面
export function putpresentationslide(data) {
  return request({
    url: '/ppt/presentation/slide',
    method: 'put',
    data,
  })
}

// 删除 PPT 页面
export function deletepresentationslide(data) {
  return request({
    url: '/ppt/presentation/slide',
    method: 'delete',
    data,
  })
}

// PPT 页面位置更新
export function postrecitifyorder(data) {
  return request({
    url: '/ppt/presentation/slide/recitifyorder',
    method: 'post',
    data,
  })
}

// PPT 页面名称修改
