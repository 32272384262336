<template>
  <div class="addEditReport">
    <el-dialog
      class=" thmemBottomMenu"
      :title="dialogAddPPT ? '创建报告' : '编辑报告'"
      :visible.sync="dialogvisible"
      :close-on-click-modal="false"
      width="40%"
      @close="closeDialog"
    >
      <el-form :model="form" label-width="100px">
        <el-form-item label="报告名称：" required>
          <el-input v-model="form.pptname" autocomplete="off" maxlength="20" placeholder="不超过20个字"></el-input>
        </el-form-item>
        <el-form-item label="报告描述：">
          <el-input type="textarea" v-model="form.pptdesc" autocomplete="off" maxlength="100" placeholder="不超过100个字"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="dialogvisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="setUnit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { postpresentation, putpresentation } from '@/api/reportAPI'
import defaultData from './defaultData'
export default {
  props: ['dialogAddPPT', 'dialogEditPPT', 'editinfo'],
  data() {
    return {
      dialogvisible: false,
      form: {
        pptname: '',
        pptdesc: '',
      },
    }
  },
  watch: {
    dialogAddPPT: function() {
      if (this.dialogAddPPT) this.dialogvisible = this.dialogAddPPT
    },
    dialogEditPPT: function() {
      if (this.dialogEditPPT) {
        this.dialogvisible = this.dialogEditPPT
      }
    },
    editinfo: function() {
      if (this.editinfo) {
        const { name, content } = this.editinfo
        this.form.pptname = name
        this.form.pptdesc = content
      }
    },
    dialogvisible: function() {
      if (!this.dialogvisible) {
        this.$emit('changeAddEditPPT', this.dialogvisible)
        this.$emit('changeAddEditReport', this.dialogvisible)
      }
    },
  },
  computed: {},
  created() {},
  methods: {
    setUnit() {
      if (!this.form.pptname) {
        this.membertips = '报告名称不能为空！'
        return
      }
      const slides = _.cloneDeep(defaultData.initialPPT).map(x => {
        x.settings = JSON.stringify(x.settings)
        x.elements = x.elements.map(ele => {
          ele.definition = JSON.stringify(ele.definition)
          ele.content = JSON.stringify(ele.content)
          ele.settings = JSON.stringify(ele.settings)
          return ele
        })
        return x
      })
      let param = {
        name: this.form.pptname,
        content: this.form.pptdesc,
        slides,
      }
      if (this.dialogAddPPT) {
        postpresentation(param).then(v => {
          this.$emit('changepptlist')
          this.dialogvisible = false
        })
      }
      if (this.dialogEditPPT) {
        param.id = this.editinfo.id
        putpresentation(param).then(v => {
          this.$emit('changepptlist')
          this.dialogvisible = false
        })
      }
    },
    closeDialog() {
      if (this.editinfo) {
        const { name, content } = this.editinfo
        this.form.pptname = name
        this.form.pptdesc = content
      } else {
        this.form.pptname = ''
        this.form.pptdesc = ''
      }
    },
  },
}
</script>

<style lang="scss">
.addEditReport {
  .el-dialog {
    .el-form {
      .el-form-item {
        .el-input {
          input {
            padding: 5px 15px;
          }
        }

        .el-textarea {
          textarea {
            min-height: 100px !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.addEditReport {
  .el-dialog {
    max-width: 715px;
    min-width: 500px;
    .el-form {
      width: 80%;
      margin: 0 auto;
    }
  }
}
</style>
