import pptxgen from 'pptxgenjs'

// 一个PPT 返回一个 页面的数组 每个页面有一个内容数组
// 内容数组 有 文字/图片
// 导出时根据每一页的内容数组 设置每一个幻灯片的配置内容

//每添加一个页面保存的时候 把当前页面的内容 转化成一个数组（卡片要）
// 页面根据 数据生成

const exportpptjs = {
  ppt_export(pptData, loading) {
    // 创建PPT
    let pptx = new pptxgen()
    // 基础设置
    pptx.defineLayout({ name: 'A3', width: 13.333, height: 7.5 })
    // pptx.title = '测试111'
    // pptx.subject = '测试222'
    pptx.layout = 'A3'

    //添加幻灯片
    let pgidx = 0
    pptData.slides.forEach(slide => {
      if (!['Cover', 'BackCover'].includes(slide.slideType)) {
        pgidx++
      }
      addPages(pptx, slide, pgidx)
    })

    // 绘制PPT
    pptx
      .writeFile({
        fileName: pptData.name,
      })
      .then(() => {
        loading.close()
      })
      .catch(err => {
        console.error(err)
        loading.close()
      })
  },
}

function addPages(pptx, currentPage, idx) {
  // 添加一张幻灯片
  let slide = pptx.addSlide()
  slide.background = { fill: currentPage.settings.background }
  //幻灯片内容
  currentPage.elements.forEach(item => {
    const position = item.settings.position
    const textStyle = item.settings.textStyle
    //文本
    if (item.elementType === 'TextBox') {
      const textboxText = [{ text: item.content.text, options: { breakLine: false } }]
      const textboxOpts = {
        ...position,
        color: textStyle.color,
        fontSize: textStyle.fontSize,
        bold: textStyle.bold,
        fontFace: '微软雅黑',
        align: 'bottom',
        valign: 'top',
      }
      slide.addText(textboxText, textboxOpts)
    }

    //标题
    if (item.elementType === 'Title') {
      const mainText = [{ text: item.content.maintitle }]
      const subText = [{ text: item.content.subtitle }]
      const bgtext = { shape: pptx.ShapeType.rect, color: 'ffffff', fontSize: 24, bold: true, align: 'left', fontFace: '微软雅黑', fill: { color: 'd03a2b' } }
      const bgtext2 = { shape: pptx.ShapeType.rect, color: 'd03a2b', fontSize: 24, bold: true, fontFace: '微软雅黑', fill: { color: 'ffffff' } }
      if (item.settings.titleStyle === 'main') {
        const bg = { x: 0, y: '7.45%', w: '73.3%', h: '8.4%', ...bgtext }
        slide.addText(mainText, bg)
      }
      if (item.settings.titleStyle === 'mainandsub') {
        const bg = { x: 0, y: '7.45%', w: '23.42%', h: '8.4%', ...bgtext }
        slide.addText(mainText, bg)
        const bg2 = { x: '23.42%', y: '7.45%', w: '49.9%', h: '8.4%', ...bgtext2 }
        slide.addText(subText, bg2)
      }
      if (item.settings.titleStyle === 'catalogue') {
        const bg = { x: 0, y: '7.45%', w: '23.42%', h: '8.4%', ...bgtext }
        slide.addText(mainText, bg)
      }
    }

    //图片
    if (item.elementType === 'Image') {
      if (item.content.imgurl) {
        slide.addImage({ x: position.x, y: position.y, w: position.w, h: position.h, path: item.content.imgurl })
      }
    }

    // 目录
    if (item.elementType === 'Catalogue') {
      item.content.catalogueList.forEach((cata, i) => {
        const textposition = {
          x: '45%',
          y: 25 + i * 12 + '%',
        }
        const tipPosition = {
          x: '43%',
          y: 22 + i * 12 + '%',
        }
        const text = {
          x: textposition.x,
          y: textposition.y,
          w: '40%',
          h: '7%',
          shape: pptx.ShapeType.rect,
          fontSize: 24,
          color: textStyle.color,
          align: 'center',
          fontFace: '微软雅黑',
          line: { color: '333333', width: 1 },
        }
        slide.addText(cata.text, text)
        const list = {
          x: tipPosition.x,
          y: tipPosition.y,
          w: 0.62,
          h: 0.62,
          shape: pptx.ShapeType.rect,
          color: 'ffffff',
          fontSize: 30,
          bold: true,
          margin: 0,
          fontFace: '微软雅黑',
          align: 'center',
          fill: { color: 'd03a2b' },
        }
        slide.addText('0' + (i + 1), list)
      })
    }

    //图表
    if (item.elementType === 'Chart') {
      if (item.content.imgurl) {
        slide.addImage({ x: position.x, y: position.y, w: position.w, h: position.h, path: item.content.imgurl })
      }
    }

    //非封面封底页增加 页码idx
    if (!['Cover', 'BackCover'].includes(currentPage.slideType)) {
      const textcolor = currentPage.slideType === 'Chapter' ? 'd03a2b' : 'ffffff'
      const fillcolor = currentPage.slideType === 'Chapter' ? 'ffffff' : 'd03a2b'
      const pgnum1 = { x: '90%', y: '96%', w: '0.4%', h: '4%', shape: pptx.ShapeType.rect, fill: { color: fillcolor } }
      const pgnum2 = { x: '90.7%', y: '96%', w: '0.8%', h: '4%', shape: pptx.ShapeType.rect, fill: { color: fillcolor } }
      const pgnum3 = { x: '91.8%', y: '96%', w: '2%', h: '4%', shape: pptx.ShapeType.rect, fill: { color: fillcolor } }
      const pgnum4 = {
        x: '94.1%',
        y: '96%',
        w: '5.9%',
        h: '4%',
        shape: pptx.ShapeType.rect,
        fontSize: 12,
        color: textcolor,
        align: 'right',
        fill: { color: fillcolor },
      }
      slide.addText('', pgnum1)
      slide.addText('', pgnum2)
      slide.addText('', pgnum3)
      slide.addText(idx, pgnum4)
    }
  })
}

export default exportpptjs
